import { useState, useMemo, useEffect, useCallback } from 'react'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, InboundScheduleUI, OutboundQuarantineInterval, SettingValue } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings,
  updateSchedule
} from 'redux/features/settings/settingsSlice'
import { getPdDomain, getVerifiedDomainList } from 'redux/features/domains/domainsSlice'
import { isSuccess, isPending } from 'redux/toolkit/api'
import { isEmailValid } from 'lib/validation'
import { convertScheduleToArr } from 'lib/convertSchedule'
import { ScheduleMatrixProps } from 'components/libs/scheduler/schedulerMatrixTypes'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'
import { useOutboundSettingsRights } from 'components/libs/userRights/pages/useOutboundSettingsRights'
import { getBlockTransition, setBlockTransition } from 'lib/routes'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

export interface State {
  canEditSpamNotificationAddress: boolean
  form: FormState
  formErrors: FormErrors
  isUpdatePending: boolean
  isDirtyForm: boolean
  key: string
}

interface FormState {
  [AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL]: string
  [AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL]: string
  [AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]: string
  [AvailableSettings.SENDER_NOTIFICATION_ENABLE]: string
  [AvailableSettings.SENDER_NOTIFICATION_ADDRESS]: string
  [AvailableSettings.SENDER_NOTIFICATION_SUBJECT]: string
  [AvailableSettings.SENDER_NOTIFICATION_TEMPLATE]: string
  [AvailableSettings.REJECT_NOTIFICATION_ADDRESS]: string
  [AvailableSettings.REJECT_NOTIFICATION_SUBJECT]: string
  [AvailableSettings.REJECT_NOTIFICATION_TEMPLATE]: string
  [AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]?: string
}

interface FormErrors {
  [AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]?: string
  [AvailableSettings.SENDER_NOTIFICATION_ADDRESS]?: string
  [AvailableSettings.REJECT_NOTIFICATION_ADDRESS]?: string
  [AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]?: string
}

export interface EventHandlers {
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onSave: () => void
  onCancelConfirm: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

export type UseNotificationLogic = [State, EventHandlers, ScheduleMatrixProps]

const SETTINGS_LIST = [
  AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL,
  AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL,
  AvailableSettings.OUTBOUND_QUARANTINE_EMAIL,
  AvailableSettings.SENDER_NOTIFICATION_ENABLE,
  AvailableSettings.SENDER_NOTIFICATION_ADDRESS,
  AvailableSettings.SENDER_NOTIFICATION_SUBJECT,
  AvailableSettings.SENDER_NOTIFICATION_TEMPLATE,
  AvailableSettings.REJECT_NOTIFICATION_ADDRESS,
  AvailableSettings.REJECT_NOTIFICATION_SUBJECT,
  AvailableSettings.REJECT_NOTIFICATION_TEMPLATE
]

export enum FormStatus {
  VALID,
  INVALID
}

export const useNotificationsLogic = (): UseNotificationLogic => {
  const [formObject, setFormObject] = useState<FormState>({
    [AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL]: OutboundQuarantineInterval.IMMEDIATELY,
    [AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL]: '',
    [AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]: '',
    [AvailableSettings.SENDER_NOTIFICATION_ENABLE]: SettingValue.DISABLED,
    [AvailableSettings.SENDER_NOTIFICATION_ADDRESS]: '',
    [AvailableSettings.SENDER_NOTIFICATION_SUBJECT]: '',
    [AvailableSettings.SENDER_NOTIFICATION_TEMPLATE]: '',
    [AvailableSettings.REJECT_NOTIFICATION_ADDRESS]: '',
    [AvailableSettings.REJECT_NOTIFICATION_SUBJECT]: '',
    [AvailableSettings.REJECT_NOTIFICATION_TEMPLATE]: '',
    [AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]: ''
  })
  const [formErrors, setFormErrors] = useState({})
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  // key is used to force a re-render of <SettingsPage>
  // The Cancel button is supposed to reset the initial form values only after a confirmation.
  // The need for a confirmation (block) is set up via useDirtyFormCheck
  const [key, setKey] = useState(makeUuid())

  const dispatch = useAppDispatch()

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    isUpdateAccountSettingsPending,
    isUpdateDomainSettingsPending,
    accountSettings,
    domainSettings,
    verifiedDomains,
    pdDomain,
    customOutboundSchedule,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus),
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    verifiedDomains: _stores.domains.verifiedDomainList,
    pdDomain: _stores.domains.pdDomain,
    customOutboundSchedule: _stores.settings.customOutboundSchedule,
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus)
  }))

  const [{ isDirty, changedSettings }, resetInitialForm] = useDirtyFormObjectCheck(formObject)

  const { canEditSpamNotificationAddress } = useOutboundSettingsRights()

  // init
  useEffect(() => {
    if (canEditSpamNotificationAddress) {
      SETTINGS_LIST.push(AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS)
    }
    if (accessTokenObject?.pdDomainId) {
      dispatch(getPdDomain())
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getVerifiedDomainList())
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  useEffect(() => {
    if (isGetAccountSettingsSuccess || isGetDomainSettingsSuccess) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAccountSettingsSuccess, isGetDomainSettingsSuccess])

  const resetForm = useCallback(() => {
    const setting = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const nextForm = {
      [AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL]:
        (setting[AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL] as string) || OutboundQuarantineInterval.IMMEDIATELY,
      [AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL]:
        (setting[AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL] as any) || '',
      [AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]:
        (setting[AvailableSettings.OUTBOUND_QUARANTINE_EMAIL] as string) || '',
      [AvailableSettings.SENDER_NOTIFICATION_ENABLE]:
        (setting[AvailableSettings.SENDER_NOTIFICATION_ENABLE] as string) || SettingValue.DISABLED,
      [AvailableSettings.SENDER_NOTIFICATION_ADDRESS]:
        (setting[AvailableSettings.SENDER_NOTIFICATION_ADDRESS] as string) || '',
      [AvailableSettings.SENDER_NOTIFICATION_SUBJECT]:
        (setting[AvailableSettings.SENDER_NOTIFICATION_SUBJECT] as string) || '',
      [AvailableSettings.SENDER_NOTIFICATION_TEMPLATE]:
        (setting[AvailableSettings.SENDER_NOTIFICATION_TEMPLATE] as string) || '',
      [AvailableSettings.REJECT_NOTIFICATION_ADDRESS]:
        (setting[AvailableSettings.REJECT_NOTIFICATION_ADDRESS] as string) || '',
      [AvailableSettings.REJECT_NOTIFICATION_SUBJECT]:
        (setting[AvailableSettings.REJECT_NOTIFICATION_SUBJECT] as string) || '',
      [AvailableSettings.REJECT_NOTIFICATION_TEMPLATE]:
        (setting[AvailableSettings.REJECT_NOTIFICATION_TEMPLATE] as string) || '',
      [AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]:
        (setting[AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS] as string) || ''
    }
    resetInitialForm(nextForm)
    setFormObject(nextForm)
  }, [accessTokenObject, domainSettings, accountSettings, resetInitialForm])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      if (
        name === AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL ||
        name === AvailableSettings.SENDER_NOTIFICATION_ENABLE
      ) {
        setFormObject({ ...formObject, [name]: newValue } as FormState)
      } else {
        setFormObject({ ...formObject, [name]: newValue.target.value } as FormState)
      }
    },
    [formObject]
  )

  const validateDomainManagedEmail = useCallback(
    (email?: string) => {
      if (!email) {
        return ''
      }

      switch (true) {
        case !!accessTokenObject?.pdDomainId:
          return email.split('@')[1] !== pdDomain?.domainName ? 'email_belong_to_manage_domain' : ''
        case !accessTokenObject?.pdDomainId:
          return !verifiedDomains.some(domain => domain.domainName === email.split('@')[1])
            ? 'email_belong_to_manage_domain'
            : ''
        default:
          return ''
      }
    },
    [accessTokenObject?.pdDomainId, pdDomain, verifiedDomains]
  )

  const validateListEmails = useCallback((emails: string) => {
    const emailArray = emails.split(',')
    const notValidEmail = emailArray.find(email => !isEmailValid(email))

    return notValidEmail || ''
  }, [])

  const validateForm = useCallback((): FormStatus => {
    const nextFormErrors: FormErrors = {
      [AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]: validateListEmails(
        formObject[AvailableSettings.OUTBOUND_QUARANTINE_EMAIL]
      ),
      [AvailableSettings.SENDER_NOTIFICATION_ADDRESS]:
        formObject[AvailableSettings.SENDER_NOTIFICATION_ENABLE] === SettingValue.ENABLED
          ? validateDomainManagedEmail(formObject[AvailableSettings.SENDER_NOTIFICATION_ADDRESS])
          : '',
      [AvailableSettings.REJECT_NOTIFICATION_ADDRESS]: validateDomainManagedEmail(
        formObject[AvailableSettings.REJECT_NOTIFICATION_ADDRESS]
      ),
      [AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]: validateListEmails(
        formObject[AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS] || ''
      )
    }
    setFormErrors(nextFormErrors)
    const isValid = Object.values(nextFormErrors).every(error => !error)

    return isValid ? FormStatus.VALID : FormStatus.INVALID
  }, [formObject, validateDomainManagedEmail, validateListEmails])

  const onSave = useCallback(() => {
    const formStatus = validateForm()
    if (formStatus === FormStatus.VALID) {
      if (!canEditSpamNotificationAddress) {
        delete changedSettings[AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS]
      }
      const updatedSettings = { ...changedSettings }
      if (updatedSettings[AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL]) {
        try {
          updatedSettings[AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL] = OutboundQuarantineInterval.CUSTOM
          const currentSchedule = JSON.parse(
            updatedSettings[AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL] as string
          )
          const transformedSchedule = convertScheduleToArr(currentSchedule)
          updatedSettings[AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL] = transformedSchedule
        } catch (error) {
          dispatch(
            setErrorSnackBar({
              message: 'error_parsing_settings'
            })
          )
        }
      }
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: updatedSettings
          })
        )
      } else {
        dispatch(
          updateAccountSettings({
            settings: updatedSettings
          })
        )
      }
    }
  }, [dispatch, accessTokenObject, validateForm, canEditSpamNotificationAddress, changedSettings])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        setBlockTransition(undefined)
        resetForm()
        setKey(makeUuid())
      })
    }
  }, [resetForm])

  const onScheduleMatrixChange = useCallback(
    (schedule: InboundScheduleUI) => {
      dispatch(updateSchedule(schedule))
      try {
        const updatedFormObject: FormState = {
          ...formObject,
          [AvailableSettings.CUSTOM_OUTBOUND_QUARANTINE_INTERVAL]: schedule ? JSON.stringify(schedule) : ''
        }
        setFormObject(updatedFormObject)
      } catch (error) {
        dispatch(
          setErrorSnackBar({
            message: 'error_parsing_settings'
          })
        )
      }
    },
    [dispatch, formObject]
  )

  return useMemo(
    () => [
      {
        canEditSpamNotificationAddress,
        form: formObject,
        formErrors,
        isUpdatePending: isUpdateAccountSettingsPending || isUpdateDomainSettingsPending,
        isDirtyForm: isDirty,
        key
      },
      {
        onFormChange,
        onSave,
        onCancelConfirm,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        }
      },
      {
        isEnabled: true,
        customInboundSchedule: customOutboundSchedule,
        doUpdate: onScheduleMatrixChange
      }
    ],
    [
      canEditSpamNotificationAddress,
      formObject,
      formErrors,
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending,
      isDirty,
      key,
      onFormChange,
      onSave,
      onCancelConfirm,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      customOutboundSchedule,
      onScheduleMatrixChange
    ]
  )
}
