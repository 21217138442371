import React, { useMemo } from 'react'

import { Typography } from '@barracuda-internal/bds-core'
import styles from 'components/libs/layout/pages/tableTextStyle'

export type TableTextProps = {
  dataText: string | number | undefined
  truncated?: boolean
  dataTestId?: string
}

export const TableText: React.FC<TableTextProps> = React.forwardRef(
  ({ dataText, truncated = false, dataTestId }: TableTextProps, _) => {
    const classes = styles()

    return useMemo(
      () => (
        <Typography
          className={truncated ? classes.ellipsisText : classes.tableText}
          variant="body2"
          data-testid={dataTestId}
        >
          {dataText}
        </Typography>
      ),
      [classes.ellipsisText, classes.tableText, dataTestId, dataText, truncated]
    )
  }
)
