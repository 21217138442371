import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  tabs: {
    '& div[role=tablist]': {
      flexWrap: 'wrap',
      '& .Mui-selected': {
        borderBottom: '2px solid #067CC1'
      }
    }
  },
  tableWithError: {
    '& tr:first-child': {
      '& td': {
        verticalAlign: 'top'
      }
    }
  },
  smallCell: {
    paddingTop: '5px !important',
    paddingBottom: '5px !important'
  },
  bulkEdit: {
    marginLeft: 'auto',
    marginBottom: theme.spacing(1)
  },
  sectionHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: '-webkit-linear-gradient(top,rgba(224,224,224,1) 0,rgba(229,229,229,1) 10%,rgba(230,230,230,1) 100%)'
  },
  headerDropdown: {
    minWidth: 120,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },
  smallDropdown: {
    minWidth: 120
  },
  mediumDropdown: {
    minWidth: 220
  },
  ignore: {
    color: colors.scaleGray500
  },
  block: {
    color: colors.red500
  },
  check: {
    color: colors.green300
  },
  quarantine: {
    color: colors.orange300
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  errorColor: {
    color: colors.red600
  },
  successColor: {
    color: colors.green300
  },
  warningColor: {
    color: colors.orange300
  },
  ignoreColor: {
    color: colors.scaleGray300
  },
  titleContainer: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(0.25),
    color: colors.blue600
  },
  selectRowWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  spaceGrid: {
    marginTop: theme.spacing(3)
  },
  headerTextArea: {
    border: 'none',
    overflow: 'scroll'
  },
  mediumArea: {
    minWidth: 300
  },
  errorText: {
    fontStyle: 'italic',
    color: colors.red600
  },
  helpText: {
    color: '#646464'
  }
}))
