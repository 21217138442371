import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction, ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, SettingValue } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { RadioOptions } from 'components/libs/settings/SettingsRowRadio'
import {
  AVAILABLE_CONTENT_INTENT_ACTIONS,
  AVAILABLE_CPL_CONTENT_INTENT_ACTIONS,
  AntiPhishingSettings,
  BafiActions,
  ContentIntentActions,
  EmailBannersActions
} from 'types/AntiPhishing'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'
import { isEmailValid } from 'lib/validation'
import { useFormatMessage } from 'lib/localization'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import appConfig from 'config/appConfig'
import routesConfig from 'lib/routesConfig'

export interface State {
  isCplAccount?: boolean
  bafiOptions: RadioOptions[]
  contentIntentActions: string[]
  form: AntiPhishingSettings
  isDisabledSubmit: boolean
  isDisabledNavigation: boolean
  error: string
  isEmailBannerEnabled: boolean
}

export interface EventHandlers {
  onBulkEdit: () => void
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onSave: () => void
  onCancelConfirm: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsAntiPhishingTableDirty: Dispatch<SetStateAction<boolean>>
  onInputFormChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export type UseAntiPhishingLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.BAFI,
  AvailableSettings.EXT_SENDER_WARNING,
  AvailableSettings.INTENT,
  AvailableSettings.REDIRECTOR_CONTENT_ACTION,
  AvailableSettings.LINKPROTECT,
  AvailableSettings.TYPOSQUAT,
  AvailableSettings.LINKED_DOMAIN_POLICY,
  AvailableSettings.EMAIL_BANNERS,
  AvailableSettings.EMAIL_BANNERS_TESTING_USERS
]

const initialForm: AntiPhishingSettings = {
  [AvailableSettings.BAFI]: BafiActions.BLOCK,
  [AvailableSettings.EXT_SENDER_WARNING]: SettingValue.DISABLED,
  [AvailableSettings.INTENT]: SettingValue.ENABLED,
  [AvailableSettings.REDIRECTOR_CONTENT_ACTION]: ContentIntentActions.DEFER,
  [AvailableSettings.INTENT_REALTIME]: SettingValue.DISABLED,
  [AvailableSettings.LINKPROTECT]: SettingValue.DISABLED,
  [AvailableSettings.TYPOSQUAT]: SettingValue.DISABLED,
  [AvailableSettings.EMAIL_BANNERS]: EmailBannersActions.OFF,
  [AvailableSettings.EMAIL_BANNERS_TESTING_USERS]: ''
}

const BASE_I18N_KEY = 'ess.inbound_settings.anti_phishing'

export const useAntiPhishingLogic = (): UseAntiPhishingLogic => {
  const isEmailBannerEnabled = isMyFeatureOn(FEATURES.EGD_Email_Banners_Rollout)
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [form, setForm] = useState<AntiPhishingSettings>(initialForm)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const [error, setError] = useState('')

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))
  const { canQuarantineSettings, canChangeIntentRealtime } = useInboundSettingsRights()

  const [{ isDirty, changedSettings }, resetInitialForm] = useDirtyFormObjectCheck(form)
  const [isAntiPhishingTableDirty, setIsAntiPhishingTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (canChangeIntentRealtime) {
      SETTINGS_LIST.push(AvailableSettings.INTENT_REALTIME)
    }
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  useEffect(() => {
    if (isGetAccountSettingsSuccess || isGetDomainSettingsSuccess) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAccountSettingsSuccess, isGetDomainSettingsSuccess])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      setForm({ ...form, [name]: newValue })
    },
    [form]
  )

  const resetForm = useCallback(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const nextForm = {
      [AvailableSettings.BAFI]: (settings.bafi as BafiActions) || initialForm.bafi,
      [AvailableSettings.EXT_SENDER_WARNING]:
        (settings.ext_sender_warning as SettingValue) || initialForm.ext_sender_warning,
      [AvailableSettings.INTENT]: (settings.intent as SettingValue) || initialForm.intent,
      [AvailableSettings.REDIRECTOR_CONTENT_ACTION]:
        (settings.redirector_content_action as ContentIntentActions) || initialForm.redirector_content_action,
      [AvailableSettings.INTENT_REALTIME]: (settings.intent_realtime as SettingValue) || initialForm.intent_realtime,
      [AvailableSettings.LINKPROTECT]: (settings.linkprotect as SettingValue) || initialForm.linkprotect,
      [AvailableSettings.TYPOSQUAT]: (settings.typosquat as SettingValue) || initialForm.typosquat,
      [AvailableSettings.EMAIL_BANNERS]: (settings.email_banners as EmailBannersActions) || initialForm.email_banners,
      [AvailableSettings.EMAIL_BANNERS_TESTING_USERS]:
        (settings.email_banners_testing_users as string) || initialForm.email_banners_testing_users
    }
    resetInitialForm(nextForm)
    setForm(nextForm)
  }, [accessTokenObject, domainSettings, accountSettings, resetInitialForm])

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_INTENT_DOMAINS_BULK_EDIT.goto()
  }, [])

  const onSave = useCallback(() => {
    if (!isDirty) {
      return
    }

    if (form.email_banners === EmailBannersActions.TESTING_MODE) {
      if (!form.email_banners_testing_users) {
        setError(formatMessage('blank_email'))
        return
      }
      const emailArray = form.email_banners_testing_users.split(',').filter(email => !!email.trim())
      const invalidEmailList = emailArray.filter(email => !email || !isEmailValid(email))
      if (invalidEmailList.length > 0) {
        setError(formatMessage('invalid_email', { emails: invalidEmailList.join(', ') }))
        return
      }

      const uniqueEmails: Set<string> = new Set()
      const duplicates: string[] = []

      emailArray.forEach(email => {
        if (uniqueEmails.has(email)) {
          // Email is a duplicate
          duplicates.push(email)
        } else {
          // Email is unique, add it to the set
          uniqueEmails.add(email)
        }
      })
      if (duplicates.length > 0) {
        setError(formatMessage('duplicate_email', { emails: duplicates.join(', ') }))
        return
      }
    } else {
      delete changedSettings.email_banners_testing_users
    }

    if (!canChangeIntentRealtime) {
      delete changedSettings.intent_realtime
    }

    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: changedSettings
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: changedSettings }))
    }
    setError('')
  }, [dispatch, form, accessTokenObject, canChangeIntentRealtime, formatMessage, changedSettings, isDirty])

  const onCancelConfirm = useCallback(() => {
    resetForm()
  }, [resetForm])

  const onInputFormChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setForm({ ...form, [name]: value })
    },
    [form]
  )

  const bafiOptions = useMemo(() => {
    if (canQuarantineSettings) {
      return [
        {
          label: 'block',
          value: BafiActions.BLOCK
        },
        {
          label: 'quarantine',
          value: BafiActions.QUARANTINE
        },
        {
          label: 'off',
          value: BafiActions.OFF
        }
      ]
    }
    return [
      {
        label: 'block',
        value: BafiActions.BLOCK
      },
      {
        label: 'off',
        value: BafiActions.OFF
      }
    ]
  }, [canQuarantineSettings])

  const contentIntentActions = useMemo(
    () => (canQuarantineSettings ? AVAILABLE_CONTENT_INTENT_ACTIONS : AVAILABLE_CPL_CONTENT_INTENT_ACTIONS),
    [canQuarantineSettings]
  )

  return useMemo(
    () => [
      {
        canChangeIntentRealtime,
        bafiOptions,
        contentIntentActions,
        form,
        isDisabledSubmit: !isDirty,
        isDisabledNavigation: isDirty || isAntiPhishingTableDirty,
        error,
        isEmailBannerEnabled: appConfig.APP.IS_ADMIN && isEmailBannerEnabled
      },
      {
        onFormChange,
        onSave,
        onCancelConfirm,
        onBulkEdit,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsAntiPhishingTableDirty,
        onInputFormChange
      }
    ],
    [
      canChangeIntentRealtime,
      bafiOptions,
      contentIntentActions,
      form,
      isDirty,
      isAntiPhishingTableDirty,
      error,
      isEmailBannerEnabled,
      onFormChange,
      onSave,
      onCancelConfirm,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      onInputFormChange,
      onBulkEdit
    ]
  )
}
